import React from 'react';

const OffersContext = React.createContext();

function useOffers() {
  const context = React.useContext(OffersContext);

  if (!context) {
    throw new Error(`useOffers must be used within a OffersProvider`);
  }
  return context;
}
function OffersProvider(props) {
  const [offers, setOffers] = React.useState([]);
  const value = React.useMemo(() => [offers, setOffers], [offers]);
  return <OffersContext.Provider value={value} {...props} />;
}
export { OffersProvider, useOffers };