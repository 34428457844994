import React from 'react'
import Compressor from 'compressorjs'

const CompressImage = ({image, setCompressedImage}) => {
    console.log('test image '+image)
    // if (image === null) return

    new Compressor(image, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.        
          setCompressedImage(compressedResult)
          console.log(compressedResult)
        },
      });
    return (
        <>  
        </>
    )
}

export default CompressImage