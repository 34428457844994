import React, { useState } from 'react';
import firebase from "firebase";



const SingleModuleComponent = ({id, price, brand, model, power, available, height, description, width, warranty}) => {
    const [ moduleDetails, setModuleDetails ] = useState({
        price, brand, model, power, available, height, description, width, warranty
    });
    const [ isEditMode, setIsEditMode ] = useState(false);
    const [ isShowMore, setIsShowMore ] = useState(false);
    const modulesRef = firebase.firestore().collection('moduls');

    const handleShowMore = () => setIsShowMore(prev => !prev);
    const handleOpenEdit = () => setIsEditMode(true);

    const handleChangeEdit = ( e, field) => {
        setModuleDetails({
            ...moduleDetails,
            [field]: field === 'available' ? e.target.value === 'true' : e.target.value,
        });
    }

    const handleCloseEdit = () => {
        setModuleDetails({id, price, brand, model, power, available, height, description, width, warranty});
        setIsEditMode(false);
    }

    const handleSave = (e) => {
        e.preventDefault();
        modulesRef.doc(id).update(moduleDetails);
        setIsEditMode(false);
    }


    return (
        <>
            <tr
                onClick={handleShowMore}
                className="container"
            >
                <td className="col mb-2">{brand}</td>
                <td className="col m-2">{model}</td>
                <td className="col m-2">{power} Wp</td>
                <td className="col m-2">{price} zł</td>
                <td className="col m-2">{available ?
                    <span className="badge bg-success">Dostępny</span> :
                    <span className="badge bg-secondary">Niedostępny</span>}</td>
            </tr>
            {isShowMore && !isEditMode && (
                <>
                    <tr>
                        <td colSpan="5">
                            <h5 className="card-title">{brand}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{model}</h6>
                            <p className="card-text">{description}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="card-text">moc: {power} Wp</p>
                            <p className="card-text">wysokośc: {height} mm<br/>
                                szerokość: {width} mm</p>
                        </td>
                        <td>
                            <p className='card-text'>cena: {price} zł</p>
                            <p className="card-text">gwarancja: {warranty} lat</p>
                        </td>
                        <td colSpan='3'>
                            <button className="btn btn-primary" onClick={handleOpenEdit}>
                                Edytuj
                            </button>
                        </td>
                    </tr>
                </>
            )}
            {
                isShowMore && isEditMode && (<tr>
                    <td colSpan="12">
                        <form>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Brand:
                                </label>
                                <input
                                    className="form-control col-12"
                                    type="text"
                                    value={moduleDetails.brand}
                                    onChange={e => handleChangeEdit(e, 'brand')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Model:
                                </label>
                                <input
                                    className="form-control col-12"
                                    type="text"
                                    value={moduleDetails.model}
                                    onChange={e => handleChangeEdit(e, 'model')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Opis:
                                </label>
                                <textarea
                                    className="form-control col-12"
                                    rows="5"
                                    value={moduleDetails.description}
                                    onChange={e => handleChangeEdit(e, 'description')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Moc (w Wp):
                                </label>
                                <input
                                    className="form-control col-12"
                                    rows="5"
                                    value={moduleDetails.power}
                                    onChange={e => handleChangeEdit(e, 'power')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Wysokość:
                                </label>
                                <input
                                    className="form-control col-12"
                                    rows="5"
                                    value={moduleDetails.height}
                                    onChange={e => handleChangeEdit(e, 'height')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Szerokość:
                                </label>
                                <input
                                    className="form-control col-12"
                                    rows="5"
                                    value={moduleDetails.width}
                                    onChange={e => handleChangeEdit(e, 'width')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Cena:
                                </label>
                                <input
                                    className="form-control col-12"
                                    rows="5"
                                    value={moduleDetails.price}
                                    onChange={e => handleChangeEdit(e, 'price')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Gwarancja:
                                </label>
                                <input
                                    className="form-control col-12"
                                    rows="5"
                                    value={moduleDetails.warranty}
                                    onChange={e => handleChangeEdit(e, 'warranty')} />
                            </div>
                            <div className='col-12'>
                                <label className="form-label col-12">
                                    Dostępność:
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        value={'true'}
                                        checked={moduleDetails.available}
                                        onChange={e => handleChangeEdit(e, 'available')}
                                    /> TAK
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        value={'false'}
                                        checked={!moduleDetails.available}
                                        onChange={e => handleChangeEdit(e, 'available')}
                                    /> NIE
                                </label>
                            </div>
                            <div className='col-12'>
                                <button className="btn btn-success" onClick={handleSave}>Zapisz</button>
                                <button className="btn danger" onClick={handleCloseEdit}>Anuluj</button>
                            </div>
                        </form>
                    </td>
                </tr>)
            }
        </>
    )
}

export default SingleModuleComponent;