import React, { useContext } from 'react';
import { DataContext } from '../../../providers/dataProvider';
import AddModule from '../../../CSVToFirestore/AddModule';
import SingleModuleComponent from "./singleModule.component";

function sortModules(a, b) {
  if (a.brand < b.brand) {
    return -1;
  }
  if (a.brand > b.brand) {
    return 1;
  }
  if (a.power < b.power) {
    return -1;
  }
  if (a.power > b.power) {
    return 1;
  }
  return 0;
}

const DatabaseModuls = () => {
  const { moduls } = useContext(DataContext);

  const renderModulesList = () => moduls
    .sort(sortModules)
    .map((module) => {
    return (
      <SingleModuleComponent key={module.id} {...module} />
    );
  });

  return (
    <>
      <table className="table table-striped table-hover w-auto mx-auto">
        <thead>
          <tr>
            <th>
            <AddModule/>
            </th>
          </tr>
          <tr>
          <th scope="col">MARKA</th>
          <th scope="col">MODEL</th>
          <th scope="col">MOC</th>
          <th scope="col">CENA</th>
          <th scope="col">DOSTĘPNOŚĆ</th>
          </tr>
        </thead>
        <tbody>{renderModulesList()}</tbody>
      </table>
    </>
  );
};

export default DatabaseModuls;
