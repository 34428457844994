import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal, Tabs, Tab } from 'react-bootstrap-v5'
import { useFirstRender } from '../hooks/useFirstRender'
import { OfferContext } from '../providers/offerProvider'
import { DataContext } from '../providers/dataProvider'
import { UserContext } from '../providers/UserProvider'
import OfferData from '../components/profile/offerData.component'
import Price from '../components/calculator/price.component'
import PDFFillOffer from '../components/pdfFillForm/PDFFillOffer'
import { UploadImage } from '../components/uploadImage/uploadImage.component'
import Compressor from 'compressorjs'
import CompressImage from '../components/uploadImage/compressImage.component'
import firebase from 'firebase/app';
import 'firebase/firestore';

const AddOffer = () => {

  const firstRender = useFirstRender()
  const db = firebase.firestore()
  const [show, setShow] = useState(false);
  const state = useContext(OfferContext).offerState
  const dispatch = useContext(OfferContext).offerDispatch
  const user = useContext(UserContext).user
  const userRef = db.collection('users').doc(user.uid)
  const offerRef = userRef.collection('offers')
  // const [curOffer, setCurOffer] = useState(1*state.curOffer)
  // console.log(user)
  const {moduls,
    inverters,
    constructions,
    installation,
    protection,
    settings,
    opti,
    loading} = useContext(DataContext)


  const getCurrentDate = () => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}-${month<10?`0${month}`:`${month}`}-${year}`
  }

  const getCurrentYear = () => {

    let newDate = new Date()
    let year = newDate.getFullYear();

    return `${year}`
  }



  const [newOffer, setNewOffer] = useState({
    // client: {
    //   name: '',
    //   address: {
    //     street: '',
    //     number: '',
    //     city: '',
    //     postalCode: '',
    //     postalCity: ''
    //   },
    //   email: '',
    //   phone: ''
    // },
    // installation: {
    //   power: state.truePower,
    //   module: moduls.find((item) => item.id == state.moduleId),
    //   inverter: inverters.find((item) => item.id == state.inverterId),
    //   construction: constructions.find((item) => item.id == state.roofType),

    //   address: {
    //     street: '',
    //     number: '',
    //     city: '',
    //     postalCode: '',
    //     postalCity: ''
    //   },
    // }
  })

  const resetNewOffer = () => {
    setNewOffer({
      accepted: false,
      active: true,
      date: getCurrentDate(),
      image: "",
      // imageType: "image/png",
      offerNumber: user.prefix +'/O/'+ state.curOffer +'/'+ getCurrentYear(),
      nettoPrice: (1*state.nettoPrice).toFixed(2).toString(),
      vat: (state.vatTax*100).toString()+'%',
      vatValue: (state.nettoPrice*state.vatTax).toFixed(2).toString(),
      bruttoPrice: (state.nettoPrice*(1+state.vatTax)).toFixed(2).toString(),
      margin: state.margin,
      client: {
        name: '',
        address: {
          street: '',
          number: '',
          city: '',
          postalCode: '',
          postalCity: ''
        },
        email: '',
        phone: ''
      },
      installation: {
        power: (state.truePower).toString(),
        module: moduls.find((item) => item.id == state.moduleId),
        moduleCount: state.moduleCount,
        inverter: inverters.find((item) => item.id == state.inverterId),
        opti: opti.find((item) => item.id == state.optiId),
        optiCount: state.optiCount,
        construction: constructions.find((item) => item.id == state.roofType),
        irradiation: " - ",
        energyYearly: " - ",

        address: {
          street: '',
          number: '',
          city: '',
          postalCode: '',
          postalCity: ''
        },
      },
      salesman: {
        name: user.displayName,
        email: user.email,
        phone: user.phone,
        web: 'www.voltspot.pl',
        commission: state.commission,
        division: user.division
      },
      postscriptum: ""
    })
  }

  const [images, setImages] = useState([])
  // const [imageType, setImageType] = useState("image/png")
  const [compressedImage, setCompressedImage] = useState()
  // console.log(images[0]?.data_url ?? "")
  React.useEffect(() => {
    // console.log('useEffect po zmianie images')
    if (images.length != 0) {
      // setImageType(images[0].file.type)
      const image = images[0].file
      new Compressor(image, {
        mimeType: "image/jpeg",
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        width: 1920,
        convertSize: 400000,
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          const reader = new FileReader();
          let base64DataURL;

          reader.onload = (e) => {
            base64DataURL = e.target.result
            // console.log("base64DataURL")
            // console.log(base64DataURL)
            setCompressedImage(base64DataURL)

          };

          reader.readAsDataURL(compressedResult)
          // console.log("reader")
          // console.log(reader)

        },
      })
    }
  },[images])

  React.useEffect(() => {
    if (compressedImage != undefined) {
      // setNewOffer({...newOffer, image: compressedImage, imageType: imageType})
      setNewOffer({...newOffer, image: compressedImage})
      // console.log("compressedImage: ")
      // console.log(compressedImage)
    }
  },[compressedImage])
  // console.log(images)
  // console.log(newOffer.installation.module.description)
  // images[0]?.data_url ?? ""


  const handleClose = () => {
    // resetNewOffer()
    setShow(false)
  }
  const handleShow = () => {
    resetNewOffer()
    setShow(true)
  }

  const handleAdd = () => {


    userRef.update({curOffer: 1*state.curOffer+1})
    dispatch({type: 'setCurOffer', payload: state.curOffer+1})
    offerRef.add(newOffer)
    // resetNewOffer()
    setShow(false)
  }

  const handleAddress = () => {
    setNewOffer({...newOffer, installation: {...newOffer.installation, address: newOffer.client.address}})
  }

  // const [page, setPage] = useState(1)

  useEffect(() => {
    if (firstRender) return
    // console.log(document.getElementById('available')?.checked)
    // console.log(newOffer)
  }, [newOffer])
  // db.collection('moduls').add(data)
  return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Nowa oferta
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Oferta nr: {user.prefix}/O/{state.curOffer}/{getCurrentYear()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="clientsData" id="OfferTabs" className="mb-3">
              <Tab eventKey="clientsData" title="Klient">
                {/* Dane klienta */}
                <div className='card mb-3'>
                  <div className="card-header">
                    Dane klienta
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text w-25">Imię i nazwisko</span>
                    <input type="text" className="form-control" placeholder="Imię i nazwisko"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, name: e.target.value}})} required/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text w-25">Telefon</span>
                    <input type="text" className="form-control" placeholder="Numer telefonu"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, phone: e.target.value}})} required/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text w-25">E-mail</span>
                    <input type="text" className="form-control" placeholder="@"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, email: e.target.value}})} required/>
                  </div>
                  <div className="input-group">
                    <span className="input-group-text w-25">Adres</span>
                    <input type="text" className="form-control" placeholder="ulica"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, street: e.target.value}}})} required/>
                    <input type="text" className="form-control" placeholder="numer"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, number: e.target.value}}})} required/>
                  </div>
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="miasto"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, city: e.target.value}}})} required/>
                    <input type="text" className="form-control" placeholder="kod pocztowy"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, postalCode: e.target.value}}})} required/>
                    <input type="text" className="form-control" placeholder="poczta"
                           onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, address: {...newOffer.client.address, postalCity: e.target.value}}})} required/>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="installationData" title="Instalacja">
                {/* Dane instalacji */}
                <div className='card'>
                  <div className="card-header">
                    Adres Instalacji
                  </div>
                  <Button variant="secondary" size="sm" onClick={handleAddress}>
                    Kopiuj adres klienta
                  </Button>
                  {/* <div className="input-group mb-3">
              <span className="input-group-text w-25">Imię i nazwisko</span>
              <input type="text" className="form-control" placeholder="Imię i nazwisko" id='name'
                onChange={(e) => setNewOffer({...newOffer, client: {...newOffer.client, name: e.target.value}})} required/>
            </div> */}
                  <div className="input-group">
                    <span className="input-group-text w-25">Adres</span>
                    <input type="text" className="form-control" placeholder="ulica"
                           value={newOffer.installation?.address.street ?? ''}
                           onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, street: e.target.value}}})} required/>
                    <input type="text" className="form-control" placeholder="numer"
                           value={newOffer.installation?.address.number ?? ''}
                           onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, number: e.target.value}}})} required/>
                  </div>
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="miasto"
                           value={newOffer.installation?.address.city ?? ''}
                           onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, city: e.target.value}}})} required/>
                    <input type="text" className="form-control" placeholder="kod pocztowy"
                           value={newOffer.installation?.address.postalCode ?? ''}
                           onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, postalCode: e.target.value}}})} required/>
                    <input type="text" className="form-control" placeholder="poczta"
                           value={newOffer.installation?.address.postalCity ?? ''}
                           onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, address: {...newOffer.installation.address, postalCity: e.target.value}}})} required/>
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">Uzyski</span>
                    <input type="text" className="form-control" placeholder="nasłonecznienie"
                           onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, irradiation: e.target.value}})} required/>
                    <span className="input-group-text smalltext">kWh/m2</span>
                    <input type="text" className="form-control" placeholder="roczny uzysk"
                           onChange={(e) => setNewOffer({...newOffer, installation: {...newOffer.installation, energyYearly: e.target.value}})} required/>
                    <span className="input-group-text smalltext">kWh</span>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    Dane instalacji
                  </div>
                  <div className='smalltext'>
                    <OfferData/>
                    <Price/>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="description" title="Opisy">
                <div className='card mb-3'>
                  <div className="card-header">
                    {newOffer?.installation?.module?.brand ?? ""} {newOffer?.installation?.module?.model ?? ""}
                  </div>

                  <textarea className="form-control" placeholder=""
                            value={newOffer?.installation?.module?.description ?? ""}
                            onChange={(e) => setNewOffer({...newOffer,
                              installation: {...newOffer.installation,
                                module: {...newOffer.installation.module, description: e.target.value}
                              }
                            })}
                  />
                </div>
                <div className='card mb-3'>
                  <div className="card-header">
                    {newOffer?.installation?.inverter?.brand ?? ""} {newOffer?.installation?.inverter?.model ?? ""}
                  </div>

                  <textarea className="form-control" placeholder=""
                            value={newOffer?.installation?.inverter?.description ?? ""}
                            onChange={(e) => setNewOffer({...newOffer,
                              installation: {...newOffer.installation,
                                inverter: {...newOffer.installation.inverter, description: e.target.value}
                              }
                            })}
                  />
                </div>
              </Tab>
              <Tab eventKey="postscriptum" title="Uwagi i zdjęcie">
                <div className='card mb-3'>
                  <div className="card-header">
                    Dodatkowe uwagi
                  </div>
                  <textarea className="form-control" placeholder=""
                            value={newOffer?.postscriptum ?? ""}
                            onChange={(e) => setNewOffer({...newOffer,
                              postscriptum: e.target.value}
                            )}
                  />
                </div>
                <UploadImage images={images} setImages={setImages}/>
                {/* {console.log(images)} */}
                <div className="text-end">
                  <PDFFillOffer data={newOffer} />
                  <Button variant="primary" onClick={handleAdd}>
                    Zapisz ofertę
                  </Button>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Anuluj
            </Button>

          </Modal.Footer>
        </Modal>

        {/* show btn Modal */}
        {/* <button type="button" classNameName="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>


<div classNameName="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div classNameName="modal-dialog">
    <div classNameName="modal-content">
      <div classNameName="modal-header">
        <h5 classNameName="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" classNameName="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div classNameName="modal-body">
        ...
      </div>
      <div classNameName="modal-footer">
        <button type="button" classNameName="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" classNameName="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}
      </>
  );
};

export default AddOffer;
