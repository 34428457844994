import React, { createContext } from 'react'
import Spinner from '../components/spinner/spinner';
import useFirestoreData from '../hooks/useFirestoreData';

export const DataContext = createContext();

// const reducer = (state, action) => {
//    switch (action.type)
// }


const DataProvider = (props) => {

    const {
        moduls,
        inverters,
        constructions,
        installation,
        protection,
        settings,
        opti,
        users,
        offers,
        loading
    } = useFirestoreData();

    //  console.log(users)
    // const [data, setData] = React.useState({
    //    moduls: {},
    //    inverters: {},
    //    constructions: {},
    //    installation: {},
    //    protection: {},
    //    settings: {},
    //    opti: {}
    // })

    // React.useEffect(() => {
    //    setData({
    //       moduls:moduls,
    //       inverters:inverters,
    //       constructions:constructions,
    //       installation:installation,
    //       protection:protection,
    //       settings:settings,
    //       opti:opti,
    //    })
    // }, [
    //    moduls,
    //    inverters,
    //    constructions,
    //    installation,
    //    protection,
    //    settings,
    //    opti
    // ])

    // const [state, dispatch] = React.useReducer(reducer, {

    //  });

// if (loading) return <Spinner/>
// console.log(moduls)

    return (
        // <DataContext.Provider value={{dataState: state, dataDispatch: dispatch}}>
        <DataContext.Provider value={{
            moduls,
            inverters,
            constructions,
            installation,
            protection,
            settings,
            opti,
            users,
            offers,
            loading
        }}>
            {props.children}
        </DataContext.Provider>
    )
}

export default DataProvider
