import React, {useContext} from 'react';
import { NavLink, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import Navigation from '../../components/navigation/navigation.component'
import OffersView from '../../components/offersView/offersView.component'
// import Calculator from '../../Pages/calculator/calculator';
// import {
//   OffersView,
//   RejectedView,
//   ContractsView,
//   Navigation,
// } from './index';

const DatabaseOffers = () => {
  // const history = useHistory();
  // const user = useContext(UserContext);
  // if(user.user.admin) {history.push('/database')};



  let { path, url } = useRouteMatch();

  return (
      <>
        <Navigation />
        <div className="d-flex justify-content-center bd-highlight m-3">
          <ul className="nav nav-pills text-center">
            <li className="nav-item">
              <NavLink className="nav-link" to={`${url}/rejected`}>
                Odrzucone
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${url}/active`}>
                Aktywne
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${url}/contracts`}>
                Zaakceptowane
              </NavLink>
            </li>
          </ul>
        </div>

        <Switch>
          <Route path={`${path}/rejected`} render={(props) => (
              <OffersView {...props} active={false} accepted={false} />)} />
          <Route path={`${path}/active`} render={(props) => (
              <OffersView {...props} active={true} accepted={false} />)} />
          <Route path={`${path}/contracts`} render={(props) => (
              <OffersView {...props} active={false} accepted={true} />)} />
        </Switch>
      </>
  );
};
export default DatabaseOffers;
