import React, { useContext, useState } from 'react'
import { DataContext } from '../../providers/dataProvider'
import { UserContext } from '../../providers/UserProvider'
import PDFFillOffer from '../pdfFillForm/PDFFillOffer'
import AcceptOffer from '../../CSVToFirestore/AcceptOffer'
import RejectOffer from '../../CSVToFirestore/RejectOffer'
// import AddUser from '../../../CSVToFirestore/AddUser'
// import EditUser from '../../../CSVToFirestore/EditUser'
// import { NavItem } from 'react-bootstrap-v5'


const DatabaseOffers = ({active, accepted}) => {
    const [moreInfo, setMoreInfo] = useState({});
    const { offers } = useContext(DataContext)


    const toggleMoreInfo = (id) => {
        setMoreInfo((prevMoreInfo) => ({
            [id]: !prevMoreInfo[id],
        }));
    };

    function sortItems(a, b) {
        if (a.offerNumber < b.offerNumber) {
            return 1;
        }
        if (a.offerNumber > b.offerNumber) {
            return -1;
        }

        return 0;
    }
//   const [newPrice, setNewPrice] = useState()

//   const handlePriceChange = (itemeId) => {
//     const model = users.find((item) => item.id == itemeId).model
//     console.log(model, newPrice)
//     console.log(userssRef.doc(itemeId).price)
//   }

    // const offersList = users
    // const offersList = userOffers
    const offersList = offers
        .filter((item) => item.active == active && item.accepted == accepted)
        .sort(sortItems)
        .map((item) => {
            // console.log(item)
            return (
                <>
                    <tr
                        className="container"
                    >
                        <td className="col mb-2">{item?.date ?? 'b/d'}</td>
                        <td className="col mb-2">{accepted ? item.contractNumber : item?.offerNumber ?? 'b/d'}</td>
                        <td className="col mb-2">{item?.client.name ?? 'b/d'}</td>
                        <td className="col mb-2">{item?.client.email ?? 'b/d'}</td>
                        <td className="col mb-2">{item?.client.phone ?? 'b/d'}</td>
                        <td className="col mb-2">{item?.installation.power+'kWp' ?? 'b/d'}</td>
                        <td className="col m-2">{item?.bruttoPrice+' zł' ?? 'b/d'}</td>
                        <td className='col'>
                            <button
                                className='btn btn-outline-primary btn-sm'
                                onClick={() => {
                                    toggleMoreInfo(item.id);
                                }}>
                                Więcej
                            </button>
                        </td>
                    </tr>
                    {moreInfo[item.id] ? (
                        <tr>
                            <td colSpan='8'>
                                <div className="container">
                                    <div>
                                        <h5 className="text-center">{accepted ? item.contractNumber : item.offerNumber}</h5>
                                    </div>
                                    <div className="row align-items-start">
                                        <div className="col smalltext">
                                            <table>
                                                <tr>
                                                    <td className='w-25'>
                                                        Moc instalacji
                                                    </td>
                                                    <td>
                                                        {item.installation.power}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Moduł
                                                    </td>
                                                    <td>
                                                        {item.installation.moduleCount+'szt. '+item.installation.module.brand+' '+item.installation.module.model}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Falownik
                                                    </td>
                                                    <td>
                                                        {item.installation.inverter.brand+' '+item.installation.inverter.model}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Optymalizatory
                                                    </td>
                                                    <td>
                                                        {item.installation.optiCount+'szt. '+item.installation.opti.brand+' '+item.installation.opti.model}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Cena Netto
                                                    </td>
                                                    <td>
                                                        {item.nettoPrice+' zł'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        VAT {item.vat}
                                                    </td>
                                                    <td>
                                                        {item.vatValue+' zł'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Cena Brutto
                                                    </td>
                                                    <td>
                                                        {item.bruttoPrice+' zł'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Marża
                                                    </td>
                                                    <td>
                                                        {item.margin+'%'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Prowizja
                                                    </td>
                                                    <td>
                                                        {item.salesman.commission+' zł'}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col smalltext">
                                            <div className="mb-2">
                                                Klient:<br/>
                                                {item.client.name}<br/>
                                                {item.client.phone}<br/>
                                                {item.client.email}<br/>
                                                {item.client.address.street} {item.client.address.number}<br/>
                                                {item.client.address.postalCode} {item.client.address.city}
                                            </div>
                                            <div className="mb-2">
                                                Uwagi:<br/>
                                                {item.postscriptum}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <img src={item.image} alt="" width="200" />
                                        </div>
                                        <div className="col">
                                            <div className="mb-2 text-end" hidden={!active}><PDFFillOffer data={item} /></div>
                                            <div className="mb-2 text-end" hidden={!active}><AcceptOffer offer={item} /></div>
                                            <div className="mb-2 text-end" hidden={accepted}><RejectOffer offer={item} /></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ) : null}
                </>
            );
        });

    return (
        <>
            <table className="table table-striped table-hover mx-auto">
                <thead>
                <tr>
                    <th scope="col">Data</th>
                    <th scope="col">{accepted ? "Numer umowy" : "Numer oferty"}</th>
                    <th scope="col">Klient</th>
                    <th scope="col">Email</th>
                    <th scope="col">Telefon</th>
                    <th scope="col">Moc</th>
                    <th scope="col">Cena brutto</th>
                    <th scope='col' colSpan='2'></th>
                </tr>
                </thead>
                <tbody>{offersList}</tbody>
            </table>
        </>
    );
};

export default DatabaseOffers;
