import React, { useContext } from 'react'
import { UserContext } from '../providers/UserProvider';
import { Button } from 'react-bootstrap-v5'
import firebase from 'firebase/app';
import 'firebase/firestore';

const RejectOffer = (props) => {
  const db = firebase.firestore()
  const user = useContext(UserContext).user
  const userRef = db.collection('users').doc(user.uid)
  const offersRef = userRef.collection('offers')
  const offer = props.offer

  const reject = () =>{
    offersRef.doc(offer.id).update({active: !offer.active})
  }

  return (
    <Button variant="secondary" onClick={reject}>
      {offer.active ? 'Odrzuć' : 'Przywróc'}
    </Button>
  )
}

export default RejectOffer
