import React from 'react';
import ImageUploading from 'react-images-uploading';
import Compressor from 'compressorjs';

export function UploadImage({images, setImages}) {
//   const [images, setImages] = React.useState([]);
//   const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    
    setImages(imageList);
  };

  return (
    <div className="UploadImage">
      <ImageUploading
        // multiple
        value={images}
        onChange={onChange}
        // maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button className="btn btn-warning m-1"
              style={isDragging ? { color: 'green' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Dodaj zdjęcie
            </button>
            &nbsp;
            {/* <button className="btn btn-primary m-1" onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="200" />
                <div className="image-item__btn-wrapper">
                  <button className="btn btn-primary m-1" onClick={() => onImageUpdate(index)}>Zmień</button>
                  <button className="btn btn-danger m-1" onClick={() => onImageRemove(index)}>Usuń</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}