import React, {useState, useContext} from 'react'
import { PDFDocument, popGraphicsState} from 'pdf-lib'
import { Button } from 'react-bootstrap-v5'
import fontkit from '@pdf-lib/fontkit'
import download from 'downloadjs'

const PDFFillOffer = (props) => {
    const data = props.data
    const makePDF = async () => {
        // const formUrl = 'https://storage.googleapis.com/voltspot-aa420.appspot.com/datasheets/moduls/BAUER%20-%20BS-6MHB5-EL-BLACK%20-%20330Wp.pdf'
        const formUrl = 'https://storage.googleapis.com/voltspot-aa420.appspot.com/offerTemplatev3.pdf'
        // const formUrl = 'https://pdf-lib.js.org/assets/dod_character.pdf'
        const formPdfBytes = await fetch(formUrl)
            .then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(formPdfBytes)

        // Fetch the Ubuntu font
        const fontUrl = 'https://storage.googleapis.com/voltspot-aa420.appspot.com/fonts/TitilliumWeb-Light.ttf';
        const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

        // Embed the Ubuntu font
        pdfDoc.registerFontkit(fontkit);
        const ubuntuFont = await pdfDoc.embedFont(fontBytes);

        // //Change line height
        // const pages = pdfDoc.getPages()
        // pages.forEach(page => {
        //     page.setLineHeight(23)
        // })
        //Change font size
        const pages = pdfDoc.getPages()
        pages.forEach(page => {
            page.setFontSize(9)
            page.setLineHeight(10)
        })

        // console.log(formPdfBytes)
        // console.log(typeof(formPdfBytes))
        // console.log(pdfDoc.context.header.toString());
        console.log(data.installation)
//get fields names
        const form = pdfDoc.getForm()
        const fields = form.getFields()
        fields.forEach(field => {
            const type = field.constructor.name
            const name = field.getName()
            console.log(`${type}: ${name}`)
            // field.setFontSize(9)
        })
//----------------------------------------------
        // const imageBytes = await data.image[0].file.arrayBuffer()
        // console.log(data.image)
//map form fields
        const mainPhoto = form.getButton('mainPhoto')
        const nettoPrice = form.getTextField('nettoPrice')
        const bruttoPrice = form.getTextField('bruttoPrice')
        const address = form.getTextField('address')
        const client = form.getTextField('client')
        const power = form.getTextField('power')
        const irradiation = form.getTextField('irradiation')
        const contactPerson = form.getTextField('contactPerson')
        const offerNumber = form.getTextField('offerNumber')
        const date = form.getTextField('date')
        const postscriptum = form.getTextField('postscriptum')
        const moduleDescription = form.getTextField('modulDescription')
        const inverterDescription = form.getTextField('inverterDescription')
        const moduleBrand = form.getTextField('modulBrandT')
        const moduleModel = form.getTextField('modulModel')
        const inverterBrand = form.getTextField('inverterBrand')
        const construction = form.getTextField('construction')
        const energyYearly = form.getTextField('energyYearly')
        const VATvalue = form.getTextField('VATvalue')
        const VAT = form.getTextField('VAT')
        // const FIELDNAME = form.getTextField('FIELDNAME')

        // const mainImage = data.imageType == "image/jpeg" ? await pdfDoc.embedJpg(data.image) : await pdfDoc.embedPng(data.image)
        const mainImage = data.image!="" ? await pdfDoc.embedJpg(data.image) : ""
        console.log(mainImage)
//set field values
        if (mainImage!="") {mainPhoto.setImage(mainImage)}
        offerNumber.setText(data.offerNumber)
        date.setText(data.date)
        nettoPrice.setText((1*data.nettoPrice).toString()+' zł')
        VAT.setText(data.vat)
        // VAT.setText((data.vat*100).toString()+'%')
        VATvalue.setText((1*data.vatValue).toString()+' zł')
        bruttoPrice.setText((1*data.bruttoPrice).toString()+' zł')
        power.setText(data.installation.power+'kWp')
        // contactPerson.disableMultiline()
        contactPerson.setText([
                data.salesman.name,
                data.salesman.phone,
                data.salesman.email,
                data.salesman.web,
            ]
                .join('\n')
        )
        address.setText([
                data.installation.address.street,
                data.installation.address.number,
                '\n',
                data.installation.address.postalCode,
                data.installation.address.city,
            ]
                .join(' ')
        )
        client.setText([
                data.client.name, '\n',
                data.client.phone, '\n',
                data.client.email, '\n',
                data.client.address.street,
                data.client.address.number,
                '\n',
                data.client.address.postalCode,
                data.client.address.city,
            ]
                .join(' ')
        )
        moduleBrand.setText(data.installation.module.brand)
        moduleModel.setText(data.installation.module.model)
        moduleDescription.setText(data.installation.module?.description ?? "")

        inverterBrand.setText(data.installation.inverter.brand+' '+data.installation.inverter.model)
        inverterDescription.setText(data.installation.inverter?.description ?? "")
        construction.setText(data.installation.construction.type)

        irradiation.setText(data.installation.irradiation+'kWh/m'+'2')
        energyYearly.setText(data.installation.energyYearly+'kWh')

        postscriptum.setText(data.postscriptum)
        // PDFDocument.load(formUrl).then(result => {
        //     pdfDoc = result
        //     const pdfBytes = pdfDoc.save()
        // })
        // // const form = pdfDoc.getForm()
        form.updateFieldAppearances(ubuntuFont)
        form.flatten()
        const pdfBytes = await pdfDoc.save()
        download(pdfBytes, "Voltspot - oferta nr "+data.offerNumber+".pdf", "application/pdf")

    }


    return (
        <Button variant="warning" onClick={makePDF}>
            Pobierz PDF
        </Button>
    )
}

export default PDFFillOffer
