import React, { useState, useContext } from 'react'
import { Button, Modal } from 'react-bootstrap-v5'
import { UserContext } from '../providers/UserProvider';
import { DataContext } from '../providers/dataProvider'
import { OfferContext } from '../providers/offerProvider';
import firebase from 'firebase/app';
import 'firebase/firestore';

const AcceptOffer = (props) => {
  const db = firebase.firestore()
  const offer = props.offer
  const state = useContext(OfferContext).offerState
  const dispatch = useContext(OfferContext).offerDispatch
  const user = useContext(UserContext).user
  const { users } = useContext(DataContext)
  // console.log(users)
  const userRef = db.collection('users').doc(user.uid)
  const offersRef = userRef.collection('offers')

  // const [curContract, setCurContract] = useState(1*user.curContract)
  // console.log('Inicjacja zmiennej - wartość: '+curContract)

  const [show, setShow] = useState(false)
  
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  const getCurrentDate = () => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}-${month<10?`0${month}`:`${month}`}-${year}`
    }

  const getCurrentYear = () => {

    let newDate = new Date()
    let year = newDate.getFullYear();
    
    return `${year}`
    }

  const accept = () =>{
    // console.log('początek funkcji accept - wartość: '+curContract)
    offersRef.doc(offer.id).update({accepted: true})
    offersRef.doc(offer.id).update({active: false})
    offersRef.doc(offer.id).update({contractDate: getCurrentDate()})
    userRef.update({curContract: 1*state.curContract+1})
    dispatch({type:'setCurContract', payload:1*state.curContract+1})
    // setCurContract(curContract+1)
    offersRef.doc(offer.id).update({contractNumber: user.prefix +'/'+ state.curContract +'/'+ getCurrentYear()})
    // console.log('koniec funkcji accept - wartość: '+curContract)
    setShow(false)
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Akceptuj
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Akceptacja oferty nr:{offer.offerNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Potwierdzasz akceptację oferty przez {offer.client.name} <br/>
            na instalację {offer.installation.power}kWp na {offer.installation.construction.type} <br/>
            za {offer.bruttoPrice} zł Brutto?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Nie
          </Button>
          <Button variant="primary" onClick={accept}>
            Tak
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AcceptOffer
